import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikConfig } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import cx from 'classnames';
import loadable from '@loadable/component';
import moment from 'moment';
import qs from 'qs';

import 'stylesheets/rc-picker.css';

import { getTickets, setLoading } from 'store/actions';
import { AppToast } from 'components/toast';
import { AppViews } from 'endpoints';
import { trimValues } from 'utils';

import { ReactComponent as CalendarIcon } from 'images/search_engine/calendar.svg';
import { ReactComponent as PrevArrowSvg } from 'images/icons/iconfinder_chevron-left.svg';
import { ReactComponent as NextArrowSvg } from 'images/icons/iconfinder_chevron-right.svg';

import styles from '../index.module.css';
import LocationInput from './LocationInput';
import QueryString from 'qs';

const DayPickerSingleDateController = loadable(() => import('components/DayPickerSingleDateController'));

export type LostBookingIDValues = {
  email: string;
  origin: string;
  destination: string;
  departDate: string;
};

const LostBookingID: React.VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = React.useMemo(() => QueryString.parse(location.search.slice(1) as string), [location.search]);

  const [isPickerVisible, setIsPickerVisible] = React.useState(false);

  const validationSchema = Yup.object<LostBookingIDValues>({
    email: Yup.string()
      .email(t('validations:user.email.invalid'))
      .required(t('validations:required', { field: t('views:manageYourBooking.body.haveAccount.emailAddress') })),
    origin: Yup.string().required(
      t('validations:required', { field: t('views:manageYourBooking.body.lostBooking.origin') }),
    ),
    destination: Yup.string().required(
      t('validations:required', { field: t('views:manageYourBooking.body.lostBooking.destination') }),
    ),
    departDate: Yup.string().required(
      t('validations:required', { field: t('views:manageYourBooking.body.lostBooking.departDate') }),
    ),
  });

  const onSubmit = async (values: LostBookingIDValues) => {
    const { departDate, origin, destination, ...payload } = trimValues(values);

    const departureAtLocal = moment(departDate)
      .set({
        hour: moment(0, 'HH:mm').get('hour'),
        minutes: moment(0, 'HH:mm').get('minutes'),
      })
      .format('YYYY-MM-DD');

    try {
      dispatch(setLoading(true));
      const response = await dispatch(
        getTickets.request({
          departureAtLocal,
          arrivalLocationId: destination,
          departureLocationId: origin,
          ...payload,
        }),
      );

      dispatch(setLoading(false));

      if (response.length) {
        history.push({
          pathname: AppViews.BOOKINGS,
          search: `?${qs.stringify({ departureAtLocal, origin, destination, ...payload })}`,
          state: {
            tickets: response,
          },
        });
      } else {
        AppToast.info(t('views:manageYourBooking.messages.noBookingFound'));
      }
    } catch (err) {
      AppToast.error(t('validations:somethingWentWrong'));
      dispatch(setLoading(false));
    }
  };

  const getFormikProps = (): Omit<FormikConfig<LostBookingIDValues>, 'children'> => {
    const initialValues: LostBookingIDValues = {
      email: (queryParams.email as string) ?? '',
      origin: '',
      destination: '',
      departDate: '',
    };

    return {
      initialValues,
      onSubmit,
      validationSchema,
    };
  };

  return (
    <div className="offset-md-2 col-md-8">
      <Formik<LostBookingIDValues> {...getFormikProps()}>
        {({ errors, touched, values, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form onSubmit={handleSubmit}>
            <div className={cx(styles.inputGroup, { [styles.errorBottom]: touched.email && errors.email })}>
              <label htmlFor="email" className={styles.inputUnderlined}>
                <input
                  id="email"
                  name="email"
                  onChange={({ target: { value, name } }) => setFieldValue(name, value.toLowerCase().trim())}
                  onBlur={({ target: { name } }) => setFieldTouched(name)}
                  value={values.email}
                  className={values.email ? styles.presenceField : ''}
                />
                <span className={styles.inputLabel}>{t('views:manageYourBooking.body.haveAccount.emailAddress')}</span>
              </label>
            </div>
            {touched.email && errors.email && <div className={styles.error}>{errors.email}</div>}

            <div className={styles.inline}>
              <LocationInput name="origin" label={t('views:manageYourBooking.body.lostBooking.origin')} isOrigin />

              <LocationInput
                name="destination"
                id="destination"
                label={t('views:manageYourBooking.body.lostBooking.destination')}
              />
            </div>

            <div
              className={cx('mt-4', styles.inputGroup, {
                [styles.errorBottom]: touched.departDate && errors.departDate,
              })}
              onClick={() => setIsPickerVisible(true)}
            >
              <label htmlFor="departDate" className={styles.inputUnderlined}>
                <input
                  id="departDate"
                  name="departDate"
                  onChange={({ target: { value, name } }) => setFieldValue(name, value)}
                  onBlur={({ target: { name } }) => setFieldTouched(name)}
                  value={values.departDate.length > 0 ? moment(values.departDate).format('DD MMM YYYY') : ''}
                  className={cx(styles.inputBackground, { [styles.presenceField]: values.departDate })}
                  disabled
                />
                <span className={styles.inputLabel}>{t('views:manageYourBooking.body.lostBooking.departDate')}</span>
                <CalendarIcon className={styles.calendarIcon} />
              </label>
            </div>
            {touched.departDate && errors.departDate && <div className={styles.error}>{errors.departDate}</div>}

            <div className={cx('DayPickerSingleDateController', 'lostBookingIdSingleDatePicker', styles.dayPicker)}>
              {isPickerVisible && (
                <DayPickerSingleDateController
                  date={values.departDate.length > 0 ? moment(values.departDate) : moment()}
                  onDateChange={(date) => {
                    const momentDate = moment(date);

                    setFieldValue('departDate', momentDate.format('YYYY-MM-DD'));
                    setFieldTouched('departDate');
                    setIsPickerVisible(false);
                  }}
                  focused
                  onFocusChange={() => null}
                  initialVisibleMonth={() => (values.departDate.length > 0 ? moment(values.departDate) : moment())}
                  numberOfMonths={1}
                  onOutsideClick={() => {
                    setIsPickerVisible(false);
                    setFieldTouched('departDate');
                  }}
                  monthFormat="MMM YYYY"
                  // isOutsideRange={(day) => !moment().isSame(day, 'day') && !moment().isBefore(day, 'day')}
                  navPrev={
                    <div className={styles.arrowPrev}>
                      <PrevArrowSvg />
                    </div>
                  }
                  navNext={
                    <div className={styles.arrowNext}>
                      <NextArrowSvg />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                />
              )}
            </div>

            <div className="mt-4 d-flow-root">
              <div className={styles.bookingIdConfirmationInformation}>
                <p>{t('views:manageYourBooking.body.haveBooking.information')}</p>
              </div>
              <div className={`float-right ${styles.signInBtn}`}>
                <button type="submit">{t('views:manageYourBooking.body.haveBooking.submit')}</button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LostBookingID;
