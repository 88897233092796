import Faqs from './Faqs';
import StaticPages from './StaticPages';
import Locations from './Locations';
import Trips from './Trips';
import Payments from './Payments';
import PaymentMethods from './PaymentMethods';
import Users from './Users';
import Tickets from './Tickets';
import Cities from './Cities';
import Marketing from './sendgridv3/Marketing';
import DiscountCode from './DiscountCode';
import { Subscription } from './Subscription';
import NextAvailableTrips from './NextAvailableTrips';
import TopDestinations from './TopDestination';
import Checkout from './Checkout';
import QRCode from './QRCode';
import PaymentData from './PaymentData';
import setCustomer from './setCustomer';
import Calendar from './Calendar';
import Banners from './Banners';
import Routes from './Routes';

export enum AppViews {
  HOME = '/',
  HELP = '/ajuda',
  STATIC_PAGE = '/page',
  ROADMAP = '/page/nossas-linhas',
  STOPS_INFO = '/onde-embarcar',
  FAQS = '/perguntas-frequentes',
  SEARCH = '/search',
  CHECKOUT = '/checkout',
  PAYMENT_LANDING_PAGE = '/payment_status',
  PAYMENT_PENDING = '/payment_pending',
  SIGN_IN = '/entrar',
  SIGN_UP = '/sign_up',
  SIGN_IN_MOBILE = '/sign_in_mobile',
  CONFIRMATION = '/confirmation',
  CONFIRMATIONWEB = '/confirmationweb',
  DADOSCASO = '/dadoscaso',
  SIGN_UP_COMPLETED = '/sign_up_completed',
  FORGOT_PASSWORD = '/esqueci-senha',
  MANAGE_YOUR_BOOKING = '/gerencie-sua-reserva',
  ALUGUERES = '/alugueres',
  BOOKINGS = '/gerencie-sua-reserva/bookings',
  WEBTOCASE = '/webtocase',
  USER_BOOKINGS = '/users/bookings',
  USER_PROFILE = '/users/profile',
  USER_PAYMENT_METHODS = '/users/payment_methods',
  USER_ADD_PAYMENT_METHODS = '/users/payment_methods/add',
  ALTER_TRIP = '/gerencie-sua-reserva/alter_trip',
  PAYMENT_LINK = '/payment_link',
}

export enum AppViewsEn {
  HOME = '/',
  HELP = '/help',
  STATIC_PAGE = '/page',
  ROADMAP = '/page/roadmap',
  STOPS_INFO = '/stops-information',
  FAQS = '/faqs',
  SEARCH = '/search',
  CHECKOUT = '/checkout',
  PAYMENT_LANDING_PAGE = '/payment_status',
  PAYMENT_PENDING = '/payment_pending',
  SIGN_IN = '/sign_in',
  SIGN_UP = '/sign_up',
  SIGN_IN_MOBILE = '/sign_in_mobile',
  CONFIRMATION = '/confirmation',
  CONFIRMATIONWEB = '/confirmationweb',
  DADOSCASO = '/dadoscaso',
  SIGN_UP_COMPLETED = '/sign_up_completed',
  FORGOT_PASSWORD = '/forgot_password',
  MANAGE_YOUR_BOOKING = '/manage_your_booking',
  ALUGUERES = '/alugueres',
  BOOKINGS = '/manage_your_booking/bookings',
  WEBTOCASE = '/webtocase',
  USER_BOOKINGS = '/users/bookings',
  USER_PROFILE = '/users/profile',
  USER_PAYMENT_METHODS = '/users/payment_methods',
  USER_ADD_PAYMENT_METHODS = '/users/payment_methods/add',
  ALTER_TRIP = '/manage_your_booking/alter_trip',
  PAYMENT_LINK = '/payment_link',
}

const Endpoints = {
  ...Faqs,
  ...StaticPages,
  ...Marketing,
  ...Locations,
  ...Trips,
  ...Payments,
  ...PaymentMethods,
  ...Users,
  ...Tickets,
  ...Cities,
  ...DiscountCode,
  ...Subscription,
  ...NextAvailableTrips,
  ...TopDestinations,
  ...Checkout,
  ...QRCode,
  ...PaymentData,
  ...setCustomer,
  ...Calendar,
  ...Banners,
  ...Routes,
};

export default Endpoints;
