import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from 'components/loaders';

import { IStore } from 'store/reducers';

const LoadingProvider: React.FC = ({ children }) => {
  const isLoading = useSelector<IStore, boolean>((state) => state.ui.isLoading);

  return (
    <>
      <Spinner isLoading={isLoading} />
      {children}
    </>
  );
};

export default LoadingProvider;
