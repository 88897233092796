import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Email from 'components/auth/ManageYourBooking/Email';
import BookingID from 'components/auth/ManageYourBooking/BookingID';
import LostBookingID from 'components/auth/ManageYourBooking/LostBookingId';

import styles from './index.module.css';
import { Helmet } from 'react-helmet';
import QueryString from 'qs';

export enum Tab {
  haveAnAccount = 'I have an account',
  haveBookingId = 'I have the booking ID',
  lostBookingId = 'I lost my booking ID',
}

type LocationState = {
  tab?: Tab;
};

const ManageYourBooking: React.FC = () => {
  const { t } = useTranslation();
  const locationState = useLocation<LocationState>();
  const location = useLocation();

  const queryParams = React.useMemo(() => QueryString.parse(location.search.slice(1)), [location.search]);

  const [currentTab, setCurrentTab] = React.useState<Tab>(
    queryParams.bookingId
      ? Tab.haveBookingId
      : queryParams.origin
      ? Tab.lostBookingId
      : locationState.state?.tab
      ? locationState.state?.tab
      : Tab.haveAnAccount,
  );

  return (
    <>
      <Helmet>
        <title>{t(`seo:staticPagesSlug.gerencie-sua-reserva.title`)}</title>
        <meta name="description" content={t(`seo:staticPagesSlug.gerencie-sua-reserva.description`)}></meta>
      </Helmet>
      <div className={styles.header}>
        <div className={`container ${styles.container}`}>
          <h1>{t('views:manageYourBooking.header')}</h1>

          <div className={styles.body}>
            <div className="row text-center ml-0 mr-0">
              {Object.entries(Tab).map(([name, value]) => (
                <div
                  key={value}
                  className={`col-md-4 ${currentTab === value ? styles.active : styles.inactive}`}
                  onClick={() => setCurrentTab(value)}
                >
                  <h2>
                    <span>{t(`views:manageYourBooking.tabs.${name}`)}</span>
                  </h2>
                </div>
              ))}
            </div>

            <div className={`row pt-5 ml-0 mr-0 ${styles.bodyContent}`}>
              {currentTab === Tab.haveAnAccount && <Email />}
              {currentTab === Tab.haveBookingId && <BookingID />}
              {currentTab === Tab.lostBookingId && <LostBookingID />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageYourBooking;
