import React, { FC, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ScrollToTop from 'components/ScrollToTop';
import { getCurrentLocation } from 'store/actions/locations';
import { getStaticPages } from 'store/actions/static_pages';
import { TLoginSucceededPayload } from 'store/types/auth';
import { restoreUserData, setUser, setTokenPayload } from 'store/actions';
import { User } from 'models/User';
import ErrorBoundary from 'ErrorBoundary';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ExpirationToken from 'common/expiration-verify';
import './stylesheets/bootstrap.css';
import './stylesheets/react_dates_overrides.css';
import { Helmet } from 'react-helmet';
import AppCookieConsent from 'components/cookie/AppCookieConsent';
import WhatsApp from 'components/whatsapp';
import { GipsyInstanceType } from 'global';
import LoadingProvider from './providers/LoaderProvider';
import Sidebar from './components/sidebar/Sidebar';
import Header from './components/header/Header';
import PaymentMethodsFooter from './components/footer/PaymentMethodsFooter';
import Footer from './components/footer/Footer';
import Router from './routes';
import brevo from './components/brevo/index';

import styles from './App.module.css';

interface Props {
  useSuspense?: boolean;
}

const App: FC<Props> = (_props) => {
  const { i18n } = useTranslation();
  const [displaySidebar, setDisplaySidebar] = useState<boolean>(false);
  const handleHideSidebar = () => setDisplaySidebar(false);
  const handleShowSidebar = () => setDisplaySidebar(true);

  const dispatch = useDispatch();

  const updateLocalStorage = React.useCallback(
    (e: StorageEvent) => {
      if (!e.key || !e.newValue) return;

      localStorage.setItem(e.key, e.newValue);

      if (e.key === 'authData') {
        const authData: TLoginSucceededPayload | null = JSON.parse(e.newValue);

        dispatch(setTokenPayload(authData));
      }

      if (e.key === 'userData') {
        const userData: User | null = JSON.parse(e.newValue);

        dispatch(setUser(userData));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getStaticPages.request());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    dispatch(getCurrentLocation.request());
    dispatch(restoreUserData.request());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalStorage);

    return () => {
      window.removeEventListener('storage', updateLocalStorage);
    };
  }, [updateLocalStorage]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL ? (
          <Helmet>
            <meta
              name="description"
              content="BRAZIL Com a Gipsyy sua passagem sai no precinho e a sua viagem é garantida, com muito mais conforto e segurança. Confira nossos destinos e programe sua viagem!"
            />
            <meta
              name="keywords"
              content="BRAZIL gipsyy, gipsy, gypsy, passagem, passagens, passagem de ônibus, passagens de ônibus, passagem de rodoviária, ônibus, viajar de ônibus, bilhete de ônibus"
            />
          </Helmet>
        ) : (
          <Helmet>
            <meta
              name="description"
              content="PORTUGAL Reserva a tua viagem de autocarro em Portugal e na Europa com Gipsyy. Uma nova forma de viajar sem limites ✔️ Ao melhor preço. Expresso, viaja de autocarro, viajar de expresso, Viajar, viajar barato, viajar de autocarro, Bilhete autocarro gipsy, gypsy, Gipsy"
            />
            <meta
              name="keywords"
              content="PORTUGAL gipsy, gypsy, Gipsyy, Expresso, viaja de autocarro, viajar de expresso, Viajar, viajar barato, viajar de autocarro, Bilhete autocarro"
            />
            {brevo}
          </Helmet>
        )}
        <ScrollToTop />
        <LoadingProvider>
          <AppCookieConsent />
          <Sidebar displaySidebar={displaySidebar} handleHideSidebar={handleHideSidebar} />
          <div className={styles.body}>
            <nav className={styles.navigation}>
              <Header handleShowSidebar={handleShowSidebar} />
            </nav>
            <div className={styles.anchor} />
            <Router />
            <footer>
              <PaymentMethodsFooter />
              <Footer />
            </footer>
          </div>
          <WhatsApp />
        </LoadingProvider>
        <ExpirationToken />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
