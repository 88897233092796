import React, { FC } from 'react';

// import { ReactComponent as ApplePaySvg } from 'images/payment_methods/apple-pay.svg';
import { ReactComponent as GooglePaySvg } from 'images/payment_methods/google-pay.svg';
import { ReactComponent as MastercardSvg } from 'images/payment_methods/mastercard.svg';
import { ReactComponent as MbWaySvg } from 'images/payment_methods/mb-way.svg';
import { ReactComponent as MultibancoSvg } from 'images/payment_methods/multibanco.svg';
// import { ReactComponent as PayPalSvg } from 'images/payment_methods/paypal.svg';
// import { ReactComponent as PIXSvg } from 'images/payment_methods/GipsyyBR_PIX.svg';
import PIXPng from 'images/payment_methods/PIX.png';
import { ReactComponent as VisaSvg } from 'images/payment_methods/visa.svg';
import { GipsyInstanceType } from 'global';

import styles from './PaymentMethodsFooter.module.css';

const PaymentMethodsFooter: FC = () => {
  return (
    <div className={styles.block}>
      {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL && (
        <div className="container">
          <div className="row justify-content-center align-items-baseline">
            {/* <a href="https://pay.google.com" target="_blank" rel="noopener noreferrer">
              <GooglePaySvg />
            </a> */}
            {/*
          <a href="https://www.apple.com/apple-pay/" target="_blank" rel="noopener noreferrer">
              <ApplePaySvg />
            </a>
            a */}
            <a href="https://www.multibanco.pt" target="_blank" rel="noopener noreferrer">
              <MultibancoSvg />
            </a>
            <a href="https://www.mbway.pt" target="_blank" rel="noopener noreferrer">
              <MbWaySvg />
            </a>
            <a href="https://www.visa.com" target="_blank" rel="noopener noreferrer">
              <VisaSvg />
            </a>
            <a href="https://www.mastercard.com" target="_blank" rel="noopener noreferrer">
              <MastercardSvg />
            </a>
            {/*
            <a href="https://www.paypal.com" target="_blank" rel="noopener noreferrer">
              <PayPalSvg />
            </a>
              */}
          </div>
        </div>
      )}
      {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL && (
        <div className="container">
          <div className="row justify-content-center align-items-baseline">
            {/* <a href="https://pay.google.com" target="_blank" rel="noopener noreferrer">
              <GooglePaySvg />
            </a>
          <a href="https://www.apple.com/apple-pay/" target="_blank" rel="noopener noreferrer">
              <ApplePaySvg />
            </a>
            a 
            <a href="https://www.multibanco.pt" target="_blank" rel="noopener noreferrer">
              <MultibancoSvg />
            </a>
            <a href="https://www.mbway.pt" target="_blank" rel="noopener noreferrer">
              <MbWaySvg />
            </a>
              */}
            <a href="https://www.visa.com" target="_blank" rel="noopener noreferrer">
              <VisaSvg />
            </a>
            <a href="https://www.mastercard.com" target="_blank" rel="noopener noreferrer">
              <MastercardSvg />
            </a>
            <a href="https://www.bcb.gov.br" target="_blank" rel="noopener noreferrer">
              <img src={PIXPng} alt="Pix" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodsFooter;
