import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IStore } from 'store/reducers';
import { Link, useHistory } from 'react-router-dom';
import { GipsyInstanceType } from 'global';
import cx from 'classnames';
import { AppViews } from 'endpoints';
import { User } from 'models/User';
import { ReactComponent as UserSvg } from 'images/user.svg';
import { logout } from 'store/actions/auth';
import styles from './UserMenu.module.css';

const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const userIconRef = React.useRef<HTMLDivElement>(null);

  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const token = useSelector<IStore, string | null>((state) => state.auth.token);
  const currentUser = useSelector<IStore, User | null>((state) => state.users.currentUser);

  const handleOutsideClick = (e: MouseEvent) => {
    if (userIconRef.current?.contains(e.target as Node)) return;

    setShowMenu(false);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  });

  const handleClickOnMenu = React.useCallback(() => {
    if (token) {
      setShowMenu((prevShowMenu) => !prevShowMenu);
    } else {
      history.push({
        pathname: AppViews.SIGN_IN,
        search: history.location.search,
      });
    }
  }, [history, token]);

  const onLogout = async () => {
    try {
      await dispatch(logout.request());
    } finally {
      history.push({
        pathname: AppViews.HOME,
        search: history.location.search,
      });
    }
  };

  return (
    <div className={styles.menu}>
      <div className={cx(styles.user, { [styles.loggedUser]: token })} onClick={handleClickOnMenu} ref={userIconRef}>
        {token && currentUser ? (
          <span>{`${currentUser.firstName?.charAt(0)}${currentUser.lastName?.charAt(0)}`}</span>
        ) : (
          <UserSvg id="btnUser" data-testid="btnUser" />
        )}
      </div>

      {token && showMenu && (
        <div className={styles.actions}>
          <Link to={`${AppViews.USER_BOOKINGS}`} id="myBookings" data-testid="myBookings">
            <div>
              <span className={styles.text}>{t('views:header.userMenu.myBookings')}</span>
            </div>
          </Link>
          <Link to={`${AppViews.USER_PROFILE}`} id="userProfile" data-testid="userProfile">
            <div>
              <span className={styles.text}>{t('views:header.userMenu.userProfile')}</span>
            </div>
          </Link>
          <Link to={`${AppViews.USER_PAYMENT_METHODS}`} id="paymentMethods" data-testid="paymentMethods">
            <div>
              <span className={styles.text}>{t('views:header.userMenu.creditCards')}</span>
            </div>
          </Link>
          <div onClick={onLogout} id="logout" data-testid="logout">
            <span className={styles.text}>{t('views:header.userMenu.logout')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
