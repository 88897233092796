import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import { ReactComponent as CloseSvg } from 'images/icons/iconfinder_x.svg';

import { AppViews } from 'endpoints';
import { User } from 'models/User';
import { IStore } from 'store/reducers';
import { Tab } from 'components/auth/ManageYourBooking';

import StaticPage from 'models/StaticPage';

import { ReactComponent as BuySvg } from 'images/menuIcons/chronometer.svg';
import { ReactComponent as Alugueres } from 'images/menuIcons/alugueres.svg';
import { ReactComponent as Help } from 'images/menuIcons/help.svg';
import { ReactComponent as Stops } from 'images/menuIcons/station.svg';
import { ReactComponent as Trips } from 'images/menuIcons/trips.svg';

import { GipsyInstanceType } from 'global';

import styles from './Sidebar.module.css';

interface Props {
  displaySidebar: boolean;
  handleHideSidebar: () => void;
}

const idMapping: any = {
  'nossas-linhas': 'menuOurDestinations',
  'pontos-de-venda': 'menuOutlets',
  bagagem: 'menuBag',
  'transporte-de-animais': 'menuTransportAnimals',
  'sobre-nos': 'menuAbout',
};

const Sidebar: React.FC<Props> = ({ displaySidebar, handleHideSidebar }) => {
  const { t } = useTranslation();
  const pages = useSelector((state: IStore) => state.staticPages.sidebar);
  const token = useSelector<IStore, string | null>((state) => state.auth.token);
  const currentUser = useSelector<IStore, User | null>((state: IStore) => state.users.currentUser);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current?.contains(e.target as Node)) return;

      handleHideSidebar();
    },
    [handleHideSidebar],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <>
      <div className={cx(styles.background, { [styles.overlay]: displaySidebar })} />

      <div ref={ref} className={`${styles.sidebar} ${displaySidebar ? styles.show : ''}`}>
        <div className={`${styles.sidebarContent} ${displaySidebar ? styles.show : styles.hide}`}>
          <div className={styles.topSideBar}>
            <CloseSvg onClick={handleHideSidebar} className={styles.close} />
            {token ? (
              <>
                {currentUser?.firstName && (
                  <p>
                    {t(`views:sidebar.hello`)} {currentUser?.firstName} {currentUser?.lastName}
                  </p>
                )}
                <Link
                  to={AppViews.USER_BOOKINGS}
                  onClick={handleHideSidebar}
                  data-testid="menuMyTickets"
                  id="menuMyTickets"
                >
                  {t('views:sidebar.myTickets')}
                </Link>
              </>
            ) : (
              <Link
                to={{ pathname: AppViews.MANAGE_YOUR_BOOKING, state: { tab: Tab.haveAnAccount } }}
                onClick={handleHideSidebar}
                data-testid="authSignInAction"
                id="authSignInAction"
              >
                {t('views:sidebar.signIn')}
              </Link>
            )}
          </div>

          <div className={styles.staticPages}>
            <>
              <Link
                to={{ pathname: AppViews.HOME }}
                onClick={handleHideSidebar}
                data-testid="menuBuyTicket"
                id="menuBuyTicket"
              >
                <BuySvg />
                <span>{t('views:sidebar.buy')}</span>
              </Link>
              <hr />
            </>

            <>
              <Link
                to={
                  currentUser?.firstName
                    ? { pathname: AppViews.USER_BOOKINGS, state: { tab: Tab.haveBookingId } }
                    : AppViews.MANAGE_YOUR_BOOKING
                }
                onClick={handleHideSidebar}
                id="menuMyTrips"
                data-testid="menuMyTrips"
              >
                <Trips />
                <span>{t('views:sidebar.booking')}</span>
              </Link>
              <hr />
            </>

            <>
              <Link to={AppViews.HELP} onClick={handleHideSidebar} id="menuHelp" data-testid="menuHelp">
                <Help />
                <span>{t('views:sidebar.helplink')}</span>
              </Link>
              <hr />
            </>

            <>
              <Link to={AppViews.ALUGUERES} onClick={handleHideSidebar} id="menuFreight" data-testid="menuFreight">
                <Alugueres />
                <span>{t('views:sidebar.rental')}</span>
              </Link>
              <hr />
            </>

            <>
              <Link
                to={AppViews.STOPS_INFO}
                onClick={handleHideSidebar}
                id="menuWhereToBoard"
                data-testid="menuWhereToBoard"
              >
                <Stops />
                <span>{t('views:header.stopsInformation')}</span>
              </Link>
              <hr />
            </>

            {pages.map((page: StaticPage, index) => {
              return (
                <React.Fragment key={page.slug}>
                  <Link
                    to={`${AppViews.STATIC_PAGE}/${page.slug}`}
                    onClick={handleHideSidebar}
                    id={idMapping[page.slug]}
                    data-testid={idMapping[page.slug]}
                  >
                    <img src={page.imageUrl} alt={page.title} />
                    <span>{page.title}</span>
                  </Link>
                  <hr />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
