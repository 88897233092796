import { ActionType } from 'typesafe-actions';

import * as checkoutActions from 'store/actions/checkout';
import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';
import { CheckoutResponse, CheckoutPayloadLinkResponse } from 'store/types';

function* makePaymentBR(action: ActionType<typeof checkoutActions.makePaymentBR.request>) {
  try {
    const response: { data: CheckoutResponse } = yield GipsyyGDSApi.post(Endpoints.makePayment, {
      ...action.payload,
    });
    yield put(checkoutActions.makePaymentBR.success(response.data));
    yield call(resolvePromiseAction, action, response.data);
  } catch (error) {
    yield put(checkoutActions.makePaymentBR.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

function* getInformationLinkPayment(action: ActionType<typeof checkoutActions.getInformationsLinkPaymentBR.request>) {
  const { PaymentId } = action.payload;
  try {
    const response: { data: CheckoutPayloadLinkResponse } = yield GipsyyGDSApi.get(
      `${Endpoints.getLinkPayment}?PaymentId=${PaymentId}`,
    );
    yield call(resolvePromiseAction, action, response.data);
    yield put(checkoutActions.getInformationsLinkPaymentBR.success(response.data));
  } catch (error) {
    // console.log(error)
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchCheckout = [
  takeLeading(checkoutActions.makePaymentBR.request, makePaymentBR),
  takeLeading(checkoutActions.getInformationsLinkPaymentBR.request, getInformationLinkPayment),
];
