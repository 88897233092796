import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IStore } from 'store/reducers';

import * as Yup from 'yup';
import cx from 'classnames';
import qs from 'qs';

import { setLoading } from 'store/actions';
import { GipsyInstanceType } from 'global';

import { AppViews } from 'endpoints';
import { AppToast } from 'components/toast';
import axios from 'axios';
import styles from './index.module.css';
import QueryString from 'qs';

type BookingIDFormikValues = {
  bookingID: string;
  email: string;
};

const BASE_URL =
  process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL
    ? process.env.REACT_APP_GDS_WEB_API_BR
    : process.env.REACT_APP_GDS_WEB_API_PT;

const BookingID: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector<IStore, string | null>((state) => state.auth.token);
  const location = useLocation();

  const queryParams = React.useMemo(() => QueryString.parse(location.search.slice(1) as string), [location.search]);

  const validationSchema = Yup.object<BookingIDFormikValues>({
    bookingID: Yup.string()
      .min(6, t('validations:length', { length: 6 }))
      .required(t('validations:required', { field: t('views:manageYourBooking.body.haveBooking.bookingID') })),
    email: Yup.string()
      .email(t('validations:user.email.invalid'))
      .required(t('validations:required', { field: t('views:manageYourBooking.body.haveAccount.emailAddress') })),
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setFieldTouched } = useFormik<BookingIDFormikValues>({
    initialValues: {
      bookingID: (queryParams.bookingId as string) || '',
      email: (queryParams.email as string) || '',
    },
    onSubmit: async () => {
      dispatch(setLoading(true));

      try {
        const { data } = await axios.get(
          `${BASE_URL}ExternalSale/myBooking?BookingId=${values.bookingID}&UserEmail=${values.email}`,
        );
        dispatch(setLoading(false));

        if (data?.data.length) {
          history.push({
            pathname: AppViews.BOOKINGS,
            search: `?${qs.stringify({ bookingId: values.bookingID, email: values.email })}`,
            state: {
              tickets: data.data,
            },
          });
        } else {
          AppToast.info(t('views:manageYourBooking.messages.noBookingFound'));
        }
      } catch (err) {
        dispatch(setLoading(false));
        AppToast.error(t('validations:somethingWentWrong'));
      }
    },
    validationSchema,
  });

  return (
    <div className="offset-md-2 col-md-8">
      <div className="col-md-10 pl-0">
        <h5>{t('views:manageYourBooking.body.haveBooking.title')}</h5>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={cx('mt-3', styles.inputGroup, { [styles.errorBottom]: touched.bookingID && errors.bookingID })}>
          <label htmlFor="login_booking" className={styles.inputUnderlined}>
            <input
              id="login_booking"
              name="bookingID"
              onChange={({ target: { value, name } }) => setFieldValue(name, value.toUpperCase())}
              onBlur={({ target: { name } }) => setFieldTouched(name)}
              value={values.bookingID}
              className={values.bookingID ? styles.presenceField : ''}
            />
            <span className={styles.inputLabel}>{t('views:manageYourBooking.body.haveBooking.bookingID')}</span>
          </label>
        </div>
        {touched.bookingID && errors.bookingID && <div className={styles.error}>{errors.bookingID}</div>}

        <div className={cx('mt-3', styles.inputGroup, { [styles.errorBottom]: touched.email && errors.email })}>
          <label htmlFor="login_email" className={styles.inputUnderlined}>
            <input
              id="login_email"
              name="email"
              onChange={({ target: { value, name } }) => setFieldValue(name, value.toLowerCase().trim())}
              onBlur={({ target: { name } }) => setFieldTouched(name)}
              value={values.email}
              className={values.email ? styles.presenceField : ''}
            />
            <span className={styles.inputLabel}>{t('views:manageYourBooking.body.haveAccount.emailAddress')}</span>
          </label>
        </div>
        {touched.email && errors.email && <div className={styles.error}>{errors.email}</div>}

        <div className="mt-4 d-flow-root">
          <div className={styles.bookingIdConfirmationInformation}>
            <p>{t('views:manageYourBooking.body.haveBooking.information')}</p>
          </div>
          <div className={`float-right ${styles.signInBtn}`}>
            {!token && <button type="submit">{t('views:manageYourBooking.body.haveBooking.submit')}</button>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default BookingID;
