export enum AnalyticsParameterType {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
}

export type UIState = {
  isLoading: boolean;
  analyticsParameter?: AnalyticsParameterType;
};
