import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cx from 'classnames';

import { GipsyInstanceType } from 'global';
import { IStore } from 'store/reducers';

import { AppViews } from 'endpoints';

import StaticPage from 'models/StaticPage';

import logoImg from 'images/logos/gipsyy-white-transparent.png';
import logoImgBR from 'images/logos/GipsyyBR_logo_rodape1x.png';

import complaintBookImg from 'images/logos/complain-book.png';
import { ReactComponent as FacebookIcon } from 'images/logos/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'images/logos/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'images/logos/instagram.svg';
import Newsletter from './Newsletter';

import styles from './Footer.module.css';

const Footer: FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const legal = useSelector((state: IStore) => state.staticPages.legal);
  const usefulLinks = useSelector((state: IStore) => state.staticPages.usefulLinks);

  const currentYear = React.useMemo(() => moment(Date.now()).format('YYYY'), []);
  const forbiddenPaths = [AppViews.SEARCH, AppViews.CHECKOUT, AppViews.PAYMENT_LANDING_PAGE, AppViews.PAYMENT_PENDING];
  const [shouldRender, setShouldRender] = useState(true);
  useEffect(() => {
    setShouldRender(!forbiddenPaths.map((path) => location.pathname.includes(path)).some((item) => item));
  }, [location]);
  return shouldRender ? (
    <>
      <div className={styles.block}>
        <div className="container">
          <div className="row">
            {/* <div className="col-sm-12 col-md-12 col-lg-6"> */}
            {/* <h3 className={cx('text-center text-sm-left', styles.newsletterLabel)}>
                {t('views:footer.newsletterLabel')}
              </h3> */}

            {/* <Newsletter /> */}
            {/* </div> */}
            <div className="col-sm-12 col-md-12 col-lg-12 d-sm-flex flex-sm-row flex-column justify-content-between d-flex align-items-center mb-5">
              {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL && (
                <Link to={AppViews.HOME}>
                  <img src={logoImgBR} alt="Gipsyy logo" className={styles.logo} />
                </Link>
              )}
              {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL && (
                <Link to={AppViews.HOME}>
                  <img src={logoImg} alt="Gipsyy logo" className={styles.logo} />
                </Link>
              )}
              {process.env.REACT_APP_INSTANCE_TYPE === 'portugal' && (
                <a
                  className="mt-5 mt-sm-4 ml-sm-5"
                  href="https://www.livroreclamacoes.pt/inicio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width="150px" src={complaintBookImg} alt="Livro de reclamações" />
                </a>
              )}
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 pb-3">
              <div className={styles.linksHeader}>{t('views:footer.usefulLinks')}</div>
              <hr />

              <div className={styles.links}>
                <Link
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                  to={AppViews.HOME}
                >
                  {t('views:sidebar.buy')}
                </Link>
                <Link to={AppViews.STOPS_INFO}>{t('views:header.stopsInformation')}</Link>
                {usefulLinks
                  .filter((page: StaticPage) => page.slug === 'nossas-linhas' || page.slug === 'pontos-de-venda')
                  .map((page: StaticPage) => {
                    return (
                      <Link
                        key={page.slug}
                        to={`${AppViews.STATIC_PAGE}/${page.slug}`}
                        dangerouslySetInnerHTML={{ __html: page.title }}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className={styles.linksHeader}>{t('views:footer.legal')}</div>
              <hr />

              <div className={styles.links}>
                {usefulLinks
                  .filter(
                    (page: StaticPage) =>
                      page.slug === 'sobre-nos' || page.slug === 'sustentabilidade' || page.slug === 'covid19',
                  )
                  .map((page: StaticPage) => {
                    return (
                      <Link
                        key={page.slug}
                        to={`${AppViews.STATIC_PAGE}/${page.slug}`}
                        dangerouslySetInnerHTML={{ __html: page.title }}
                      />
                    );
                  })}
                {legal.map((page: StaticPage) => {
                  return (
                    <Link
                      key={page.slug}
                      to={`${AppViews.STATIC_PAGE}/${page.slug}`}
                      dangerouslySetInnerHTML={{ __html: page.title }}
                    />
                  );
                })}
              </div>
            </div>

            {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className={styles.linksHeader}>{t('views:footer.help')}</div>
                <hr />

                <div style={{ marginBottom: '40px' }} className={styles.links}>
                  <Link to={AppViews.HELP}>{t('views:header.help')}</Link>
                  <Link to={AppViews.FAQS}>{t('views:header.faqs')}</Link>
                  <Link
                    to={'/'}
                    onClick={() =>
                      window.open('https://api.whatsapp.com/send?phone=351913905135', '_blank', 'noopener,noreferrer')
                    }
                  >
                    {t('views:footer.whatsapp')}
                  </Link>
                </div>
                <div className={styles.linksHeader}>{t('views:footer.social')}</div>
                <hr />
                <section className={styles.socialIconsContainer}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/gipsyy.portugal">
                    <FacebookIcon color="#ffffff" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/gipsyy">
                    <LinkedInIcon color="#ffffff" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/gipsyy_pt/">
                    <InstagramIcon color="#ffffff" />
                  </a>
                </section>
              </div>
            )}

            {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className={styles.linksHeader}>{t('views:footer.social')}</div>
                <hr />
                <section className={styles.socialIconsContainer}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/GipsyyBrasil">
                    <FacebookIcon color="#ffffff" />
                  </a>
                  {/*
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/gipsyy">
                    <LinkedInIcon color="#ffffff" />
                  </a>
                  */}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/gipsyybr">
                    <InstagramIcon color="#ffffff" />
                  </a>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
      {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL && (
        <div className={styles.copyrights}>
          <div className="container">
            <p>&copy;{`${currentYear} ${t('views:footer.copyrights')}`}</p>
            <p>GOGIPSY DO BRASIL TECNOLOGIA E VIAGENS LTDA</p>''
            <p>CNPJ: 37.813.735/0001-44</p>
          </div>
        </div>
      )}
      {process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL && (
        <div className={styles.copyrights}>
          <div className="container">
            <p>&copy;{`${currentYear} ${t('views:footer.copyrights')}`}</p>
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default Footer;
