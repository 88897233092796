import React, { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppViews } from 'endpoints';
import logoImgBR from 'images/logos/gipsyy-original-logo.png';
import logoImg from 'images/logos/Logo-Gipsyy-and-Royal.png';
import { GipsyInstanceType } from 'global';

import styles from './Header.module.css';

import LanguageSelect from './LanguageSelect';
import UserMenu from './UserMenu';

interface Props {
  handleShowSidebar: () => void;
}

const Header: FC<Props> = ({ handleShowSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCheckoutPage = useMemo(() => {
    return location.pathname.indexOf('checkout') > 0;
  }, [location]);

  return (
    <div className="container">
      <div className={styles.mainMenu}>
        <div className={styles.leftSection}>
          {!isCheckoutPage && (
            <button onClick={handleShowSidebar} type="button" data-testid="menu" id="menu">
              <span />
              <span />
              <span />
            </button>
          )}
        </div>

        <span className={styles.centerSection}>
          <Link to={AppViews.HOME}>
            <img src={logoImg} alt="Gipsyy logo" className={styles.logo} />
          </Link>
        </span>

        <span className={styles.rightSection}>
          {!isCheckoutPage ? (
            <>
              <LanguageSelect />
              <UserMenu />
              <span className={styles.help}>
                <Link to={AppViews.HELP}>{t('views:header.help')}</Link>
              </span>
            </>
          ) : (
            <UserMenu />
          )}
        </span>
      </div>
    </div>
  );
};

export default Header;
