import { Ticket, TripLocation } from './trips';

export type PaymentsState = {
  payment: GetPaymentResponse | null;
  paymentMethods: CreditCardPaymentMethod[];
  tripPayment: TripPayment | null;
};

export enum PaymentType {
  CreditCard = 'CreditCard',
  GooglePay = 'GooglePay',
  Multibanco = 'Multibanco',
  MBWay = 'Mbway',
  PIX = 'PIX',
}

export enum PaymentStatus {
  INITIAL = 'initial',
  PENDING = 'pending',
  PENDING_3DS = 'third_d_secure_pending',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
  REFUNDED = 'refunded',
  EXPIRED = 'expired',
}

export type PaymentPayload = {
  id: string;
};

export type PaymentsPayload = {
  type: BookingType;
};

export type MakePaymentPayload = {
  id: string;
  payment: BasePayment;
  paymentMethod: PaymentMethod | SavedPaymentMethodPayload;
  billingAddress?: string | null;
  channel: 'Web';
  ticketsAttributes: TicketsAttributes[];
};

export type AddCreditCardPayload = Omit<
  MakePaymentPayload,
  'billingAddress' | 'payment' | 'id' | 'ticketsAttributes'
> & {
  addNewCard: boolean;
};

type BasePayment = {
  paymentMethodPersisted?: boolean;
  invoiceAttributes?: InvoiceAttributes;
  clientEmail: string;
  clientPhone?: string;
};

type PaymentMethod = {
  cardNumber?: string;
  expiryMonth?: string;
  expiryYear?: string;
  cvc?: string;
  holderName?: string;
  googlePayToken?: string;
  type: PaymentType;
  email?: string;
  phone?: string;
};

type SavedPaymentMethodPayload = {
  id: string;
  cvc: string;
  type: PaymentType.CreditCard;
};

export type Invoice = {
  fullName: string;
  taxId: string;
  address: string;
};

export type InvoiceAttributes = {
  fullName: string;
  taxId: string;
  address: string;
};

export type CreditCardPaymentMethod = {
  id: string;
  userId: string;
  data: {
    expiryDate: string;
    cardSummary: string;
    paymentMethod: string;
    cardHolderName: string;
  };
  status: string;
  expiryDate: string;
  default: boolean;
  createdAt: string;
};

export type TripPayment = {
  id: string;
  bookingId: string | null;
  price: string;
  userId: string;
  status: PaymentStatus;
  reservationsExpireAt: string | null;
};

export enum PaymentMethodType {
  GooglePay = 'PaymentMethods::GooglePay',
  CreditCard = 'PaymentMethods::CreditCard',
  Multibanco = 'PaymentMethods::Multibanco',
  Mbway = 'PaymentMethods::Mbway',
  PIX = 'PaymentMethods::PIX',
}

export type GetPaymentResponse = {
  id: string;
  bookingId: string | null;
  price: string;
  status: PaymentStatus;
  pspReference: string | null;
  reservationsExpireAt: string | null;
  refusalReason: string | null;
  userId: string;
  paymentMethod: {
    cardSummary?: string;
    paymentMethod?: string;
    type: PaymentMethodType;
    entity?: string;
    reference?: string;
    expiresAt?: string | null;
  };
  detailsData: PaymentDetailsData | null;
  trips: PaymentTrip[];
  canCancel?: boolean;
  isMultibanco?: boolean;
  isMbway?: boolean;
};

export type GetPaymentResponseBR = {
  paymentId: string;
  status: PaymentStatus;
  creditCard: {
    paymentId?: string;
    paymentReference?: string;
    status?: string;
    captured?: boolean;
    dateApproved: string;
  };
  pix: {
    paymentId: string;
    paymentReference: string;
    qrCodeBase64: string;
    qrCode: string;
    dateOfExpiration: string;
  };
  detailsData: PaymentDetailsData | null;
};

export type TicketsAttributes = {
  id: string;
  passengerName: string | null;
  passengerDocumentType: string | null;
  passengerDocumentNumber: string | null;
};

export type PaymentDetailsData = {
  paymentData: string;
  paymentMethodType: string;
  url: string;
  data: {
    mD: string;
    paReq: string;
    termUrl: string;
  };
  method: string;
  type: string;
};

export type PaymentTrip = {
  id: string;
  return: boolean;
  passengerAmount: number;
  arrivalAt: string;
  departureAt: string;
  arrivalAtLocal: string;
  departureAtLocal: string;
  price: string;
  babyPrice: string;
  childPrice: string;
  arrivalLocation: TripLocation;
  departureLocation: TripLocation;
  tickets: Ticket[];
  duration?: string;
};

export enum BookingType {
  UPCOMING = 'Upcoming',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}
