import { ActionType, createReducer } from 'typesafe-actions';
import { UIState } from 'store/types/ui';
import * as actions from 'store/actions';

const initialState: UIState = {
  isLoading: false,
};

export const uiReducer = createReducer<UIState, ActionType<typeof actions>>(initialState).handleAction(
  actions.setLoading,
  (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }),
);
