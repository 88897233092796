import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import SadBusIcon from './images/sadbus.svg';
import i18n from 'i18next';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.t = i18n.t.bind(i18n);
  }

  state = {
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { history } = this.props;
    if (hasError) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="card my-5" style={{ padding: 80, borderRadius: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img style={{ width: '80px' }} src={SadBusIcon} alt="Bus Icon" />
              <span style={{ marginTop: 25, fontSize: 18 }}>{this.t('general:errorBoundary.loadPageError')}</span>
              <span
                style={{ cursor: 'pointer', color: '#0077FF', marginTop: 5, fontSize: 18 }}
                onClick={() => {
                  history.push('/');
                  window.location.reload();
                }}
              >
                {this.t('general:errorBoundary.reloadText')}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ErrorBoundary);

/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unused-state */
// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { useHistory } from 'react-router';
// import SadBusIcon from './images/sadbus.svg';
// import i18n from 'i18next';

// export default class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props);
//     this.t = i18n.t.bind(i18n);
//   }

//   state = {
//     hasError: false,
//   };
//   static getDerivedStateFromError(error) {
//     return { hasError: true, error };
//   }

//   componentDidCatch(error, errorInfo) {
//     // eslint-disable-next-line no-console
//     console.log({ error, errorInfo });
//     this.setState({ errorInfo });
//   }
//   render() {
//     const { hasError } = this.state;
//     if (hasError) {
//       return (
//         <div
//           style={{
//             display: 'flex',
//             width: '100%',
//             height: '100vh',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <div className="card my-5" style={{ padding: 80, borderRadius: 10 }}>
//             <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
//               <img style={{ width: '80px' }} src={SadBusIcon} alt="Bus Icon" />
//               <span style={{ marginTop: 25, fontSize: 18 }}>{this.t('general:errorBoundary.loadPageError')}</span>
//               <span
//                 style={{ cursor: 'pointer', color: '#0077FF', marginTop: 5, fontSize: 18 }}
//                 onClick={() => {
//                   const history = useHistory();
//                   history.push('/');
//                 }}
//               >
//                 {this.t('general:errorBoundary.reloadText')}
//               </span>
//             </div>
//           </div>
//         </div>
//       );
//     }

//     return this.props.children;
//   }
// }
// ErrorBoundary.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
// };
